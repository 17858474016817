import { useMemo } from 'react';
import { useMe } from './useMe';
export function usePermission(permissions) {
    var me = useMe().data;
    return useMemo(function () {
        var _a;
        if (!(me === null || me === void 0 ? void 0 : me.permissions))
            return true;
        if (!permissions.length)
            return true;
        var userPermissions = (_a = me === null || me === void 0 ? void 0 : me.permissions) === null || _a === void 0 ? void 0 : _a.map(function (userPermission) {
            return 'ROLE_PERMISSION_'.concat(userPermission);
        });
        return permissions
            .map(function (permission) { return userPermissions.includes(permission); })
            .some(Boolean);
    }, [permissions, me]);
}
