import { useLocation } from 'react-router-dom';
import { segment } from '@/configurations/trackers/segment';
import { usePreviousLocations } from '@/routes/hooks';
export function TrackNavigation() {
    var location = useLocation();
    var setPreviousLocations = usePreviousLocations(function (state) { return state.setPreviousLocations; });
    segment.trackNavigation(location.pathname);
    setPreviousLocations(location);
    return null;
}
