/* eslint-disable class-methods-use-this */
import Logrocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { ENV } from '@/configurations/env';
var LogRocket = /** @class */ (function () {
    function LogRocket() {
        this.toSanitize = [
            'approval_code',
            'card_brand',
            'cvv',
            'expire_date',
            'holder',
            'number',
        ];
        this.instance = Logrocket;
    }
    LogRocket.prototype.init = function () {
        this.createSetup();
        setupLogRocketReact(this.instance);
    };
    LogRocket.prototype.destroy = function () {
        //
    };
    LogRocket.prototype.identify = function (user) {
        this.instance.identify(user.email);
    };
    LogRocket.prototype.createSetup = function () {
        var _this = this;
        var logRocketID = ENV.APP_LOG_ROCKET_ID;
        if (!logRocketID)
            return;
        this.instance.init(logRocketID, {
            network: {
                requestSanitizer: function (request) {
                    if (request.headers.authorization) {
                        request.headers.authorization = '';
                    }
                    var body = request.body;
                    if (typeof body !== 'string')
                        return request;
                    _this.toSanitize.forEach(function (word) {
                        if (body === null || body === void 0 ? void 0 : body.includes(word)) {
                            request.body = '';
                        }
                    });
                    return request;
                },
                responseSanitizer: function (response) {
                    if (response.headers.authorization) {
                        response.headers.authorization = '';
                    }
                    var body = response.body;
                    if (typeof body !== 'string')
                        return response;
                    _this.toSanitize.forEach(function (word) {
                        if (body === null || body === void 0 ? void 0 : body.includes(word)) {
                            response.body = '';
                        }
                    });
                    return response;
                },
            },
        });
    };
    return LogRocket;
}());
export var logRocket = new LogRocket();
