var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from 'zustand';
var MAX_PREVIOUS_LOCATIONS = 6;
export var usePreviousLocations = create(function (set) { return ({
    locations: [],
    previousLocation: null,
    setPreviousLocations: function (previousLocation) {
        set(function (state) {
            var oldLocations = state.locations.filter(function (location, index) {
                if (index === 0 && location.pathname === previousLocation.pathname)
                    return false;
                return location;
            });
            var newLocations = __spreadArray([previousLocation], oldLocations, true).slice(0, MAX_PREVIOUS_LOCATIONS);
            return __assign(__assign({}, state), { locations: newLocations, previousLocation: newLocations[1] || null });
        });
    },
}); });
