import { useMemo } from 'react';
import { parseObject } from '@agentguru/helpers';
import { parseErrorToServiceError } from '../helpers';
export function useParseErrorToServiceError(error) {
    return useMemo(function () {
        var _a, _b, _c;
        if (!error)
            return null;
        var exception = (parseErrorToServiceError(error) || {}).exception;
        if (exception === null || exception === void 0 ? void 0 : exception.details) {
            var detail = ((exception === null || exception === void 0 ? void 0 : exception.details) || [])[0];
            return {
                reason: detail === null || detail === void 0 ? void 0 : detail.reason,
                providerMessage: (_a = detail.metadata) === null || _a === void 0 ? void 0 : _a.providerMessage,
                ticketedPassengers: parseObject((_b = detail === null || detail === void 0 ? void 0 : detail.metadata) === null || _b === void 0 ? void 0 : _b.ticketedPassengers),
                notTicketedPassengers: parseObject((_c = detail === null || detail === void 0 ? void 0 : detail.metadata) === null || _c === void 0 ? void 0 : _c.notTicketedPassengers),
            };
        }
        return null;
    }, [error]);
}
