import { generatePath } from 'react-router-dom';
var login = 'login';
var bookings = 'bookings';
var search = 'search';
var bookingId = ':bookingId';
var include = 'import';
var edit = 'edit';
var serviceFee = 'service-fee';
var accessDenied = 'access-denied';
var reprice = 'reprice';
var repriceLowestPrice = 'reprice-lowest-price';
var ticketing = 'ticketing';
var tsa = 'tsa';
var contact = 'contact';
var fee = 'fee';
var payment = 'payment';
var information = 'information';
var frequentFlyers = 'frequent-flyers';
var flights = 'flights';
var results = 'results';
var workspace = 'workspace';
var create = 'create';
var quoteId = ':quoteId';
var transfer = 'transfer';
var brandedFares = 'branded-fares';
export var routes = {
    goToLogin: function () {
        return generatePath("/".concat(login));
    },
    goToBookings: function () {
        return generatePath("/".concat(bookings));
    },
    goToBookingsSearch: function () {
        return generatePath("/".concat(bookings, "/").concat(search));
    },
    goToBookingImport: function () {
        return generatePath("/".concat(bookings, "/").concat(include));
    },
    goToBooking: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId));
    },
    goToBookingEditServiceFee: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(edit, "/").concat(serviceFee));
    },
    goToBookingReprice: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(reprice));
    },
    goToBookingRepriceLowestPrice: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(repriceLowestPrice));
    },
    goToBookingEditFrequentFlyers: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(edit, "/").concat(frequentFlyers));
    },
    goToTicketingBookingEditTsa: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(edit, "/").concat(tsa));
    },
    goToTicketingBookingEditContact: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(edit, "/").concat(contact));
    },
    goToTicketingBookingEditFee: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(edit, "/").concat(fee));
    },
    goToTicketingBookingPayment: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(payment));
    },
    goToTicketingBookingInformation: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(information));
    },
    goToFlightsSearch: function () {
        return generatePath("/".concat(flights, "/").concat(search));
    },
    goToFlightsResults: function () {
        return generatePath("/".concat(flights, "/").concat(results));
    },
    goToFlightsWorkspace: function () {
        return generatePath("/".concat(flights, "/").concat(results, "/").concat(workspace));
    },
    goToFlightsCreateBooking: function (quoteId) {
        return generatePath("/".concat(flights, "/").concat(results, "/").concat(create).concat(quoteId ? "/".concat(quoteId) : ''));
    },
    goToFlightsBrandedFares: function () {
        return generatePath("/".concat(flights, "/").concat(results, "/").concat(workspace, "/").concat(brandedFares));
    },
    transfer: transfer,
    login: login,
    bookings: bookings,
    bookingId: bookingId,
    search: search,
    include: include,
    edit: edit,
    serviceFee: serviceFee,
    accessDenied: accessDenied,
    reprice: reprice,
    repriceLowestPrice: repriceLowestPrice,
    ticketing: ticketing,
    tsa: tsa,
    contact: contact,
    fee: fee,
    payment: payment,
    information: information,
    frequentFlyers: frequentFlyers,
    flights: flights,
    results: results,
    workspace: workspace,
    create: create,
    quoteId: quoteId,
    brandedFares: brandedFares,
};
