import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { trackers } from '@/configurations/trackers';
import { useAuth } from '@agentguru/auth';
import { AppAgencyRoute } from '../components/AppAgencyRoute';
import { BottomNavBarUserProfile } from '../components/BottomNavBarUserProfile';
import { ControlPanelRoute } from '../components/ControlPanelRoute';
import { routes } from '../constants';
export function useBottomNavBarRoutes() {
    var logout = useAuth().logout;
    var handleLogout = useCallback(function () {
        logout();
        trackers.destroy();
    }, [logout]);
    return useMemo(function () { return [
        {
            key: 0,
            icon: 'ag-flights',
            disabled: false,
            path: routes.goToFlightsSearch(),
            matches: [routes.flights],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 1,
            icon: 'ag-bookings',
            disabled: false,
            path: routes.goToBookings(),
            matches: [routes.bookings],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 2,
            icon: 'ag-profile',
            disabled: false,
            path: '/',
            matches: [],
            isCustomRoute: true,
            subRoutes: [
                {
                    key: 0,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'BottomNavBarUserProfile',
                    customRender: _jsx(BottomNavBarUserProfile, {}),
                },
                // {
                //   key: 1,
                //   icon: '',
                //   disabled: false,
                //   path: '/',
                //   label: 'ChangePasswordRoute',
                //   customRender: <ChangePasswordRoute />,
                // },
                // {
                //   key: 2,
                //   icon: '',
                //   disabled: false,
                //   path: '/',
                //   label: 'BottomNavBarWhatsNew',
                //   customRender: <BottomNavBarWhatsNew />,
                // },
                {
                    key: 3,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'ControlPanelRoute',
                    customRender: _jsx(ControlPanelRoute, {}),
                },
                {
                    key: 4,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'AppAgencyRoute',
                    customRender: _jsx(AppAgencyRoute, {}),
                },
                {
                    key: 5,
                    icon: 'ag-logout',
                    disabled: false,
                    path: '/',
                    label: 'Logout',
                    callback: function () { return handleLogout(); },
                    closeModalOnCallback: false,
                },
            ],
        },
    ]; }, [handleLogout]);
}
