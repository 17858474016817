var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// en
import bookingEn from './languages/en_US/booking.json';
import bookingsEn from './languages/en_US/bookings.json';
import constantsEn from './languages/en_US/constants.json';
import createBookingEn from './languages/en_US/createBooking.json';
import dialogEn from './languages/en_US/dialog.json';
import errorsEn from './languages/en_US/errors.json';
import formEn from './languages/en_US/form.json';
import layoutEn from './languages/en_US/layout.json';
import resultsEn from './languages/en_US/results.json';
import routesEn from './languages/en_US/routes.json';
import searchEn from './languages/en_US/search.json';
import sharedEn from './languages/en_US/shared.json';
import ticketingEn from './languages/en_US/ticketing.json';
import validationEn from './languages/en_US/validation.json';
import workspaceEn from './languages/en_US/workspace.json';
import brandedFaresEn from './languages/en_US/brandedFares.json';
// pt-BR
import bookingPtBr from './languages/pt_BR/booking.json';
import bookingsPtBr from './languages/pt_BR/bookings.json';
import constantsPtBr from './languages/pt_BR/constants.json';
import createBookingPtBr from './languages/pt_BR/createBooking.json';
import dialogPtBr from './languages/pt_BR/dialog.json';
import errorsPtBr from './languages/pt_BR/errors.json';
import formPtBr from './languages/pt_BR/form.json';
import layoutPtBr from './languages/pt_BR/layout.json';
import resultsPtBr from './languages/pt_BR/results.json';
import routesPtBr from './languages/pt_BR/routes.json';
import searchPtBr from './languages/pt_BR/search.json';
import sharedPtBr from './languages/pt_BR/shared.json';
import ticketingPtBr from './languages/pt_BR/ticketing.json';
import validationPtBr from './languages/pt_BR/validation.json';
import workspacePtBr from './languages/pt_BR/workspace.json';
import brandedFaresPtBr from './languages/pt_BR/brandedFares.json';
export var messages = {
    en_US: {
        translation: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, bookingEn), bookingsEn), constantsEn), createBookingEn), dialogEn), errorsEn), formEn), layoutEn), resultsEn), routesEn), searchEn), sharedEn), ticketingEn), validationEn), workspaceEn), brandedFaresEn),
    },
    pt_BR: {
        translation: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, bookingPtBr), bookingsPtBr), constantsPtBr), createBookingPtBr), dialogPtBr), errorsPtBr), formPtBr), layoutPtBr), resultsPtBr), routesPtBr), searchPtBr), sharedPtBr), ticketingPtBr), validationPtBr), workspacePtBr), brandedFaresPtBr),
    },
};
