import is from 'is_js';
var customViewportCorrectionVariable = 'vh';
function setViewportProperty(doc) {
    if (is.not.ios() && is.not.mobile())
        return;
    var prevClientHeight;
    var customVar = "--".concat(customViewportCorrectionVariable || 'vh');
    function handleResize() {
        var clientHeight = doc.clientHeight;
        if (clientHeight === prevClientHeight)
            return;
        requestAnimationFrame(function updateViewportHeight() {
            doc.style.setProperty(customVar, "".concat(clientHeight * 0.01, "px"));
            prevClientHeight = clientHeight;
        });
    }
    handleResize();
    return handleResize;
}
window.addEventListener('resize', setViewportProperty(document.documentElement));
