export var ServiceErrors;
(function (ServiceErrors) {
    ServiceErrors["GENERAL_ERROR"] = "GENERAL_ERROR";
    ServiceErrors["PNR_NOT_FOUND"] = "PNR_NOT_FOUND";
    ServiceErrors["SECURED_PNR"] = "SECURED_PNR";
    ServiceErrors["BOOKING_ALREADY_IMPORTED"] = "BOOKING_ALREADY_IMPORTED";
    ServiceErrors["PARTIAL_UPDATE"] = "PARTIAL_UPDATE";
    ServiceErrors["CREDIT_CARD_DENIAL"] = "CREDIT_CARD_DENIAL";
    ServiceErrors["PARTIAL_ISSUE"] = "PARTIAL_ISSUE";
    ServiceErrors["NAME_MISMATCH"] = "NAME_MISMATCH";
    ServiceErrors["FREQUENT_FLYER_INVALID_NUMER"] = "FREQUENT_FLYER_INVALID_NUMER";
    ServiceErrors["UNAVAILABLE_FARE"] = "UNAVAILABLE_FARE";
    ServiceErrors["NO_INTERLINE_AGREEMENT"] = "NO_INTERLINE_AGREEMENT";
})(ServiceErrors || (ServiceErrors = {}));
export var ErrorExtensionCode;
(function (ErrorExtensionCode) {
    ErrorExtensionCode["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
})(ErrorExtensionCode || (ErrorExtensionCode = {}));
