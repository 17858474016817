import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { ENV } from '@/configurations/env';
import { AuthProvider } from '@agentguru/auth';
export function AuthProviderWithRedirectCallback(_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var onRedirectCallback = function (appState) {
        navigate((appState && appState.returnTo) || window.location.pathname);
    };
    return (_jsx(AuthProvider, { domain: ENV.AUTH0_DOMAIN, clientId: ENV.AUTH0_CLIENT_ID, authorizationParams: {
            audience: ENV.AUTH0_AUDIENCE,
            redirectUri: window.location.origin,
        }, onRedirectCallback: onRedirectCallback, useRefreshTokensFallback: true, children: children }));
}
