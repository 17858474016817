import { ENV } from '../env';
export function ServiceWorkerSetup() {
    if ('serviceWorker' in navigator && ENV.NODE_ENV === 'production') {
        window.addEventListener('load', function () {
            navigator.serviceWorker
                .register('/service-worker.js')
                .catch(function (registrationError) {
                console.log('SW registration failed: ', registrationError);
            });
        });
    }
}
