import { jsx as _jsx } from "react/jsx-runtime";
import { useBreakingPoint } from '@agentguru/hooks';
import { BottomNavBar, MainLayoutBottomBar } from '@agentguru/layout';
export function AppBottomNavBar() {
    var sm = useBreakingPoint().sm;
    if (sm) {
        return (_jsx(MainLayoutBottomBar, { children: _jsx(BottomNavBar, { variant: "cockpit" }) }));
    }
    return null;
}
