import { differenceInMinutes, formatDurationTime, parseBaggageLabelWithWeight, parseDate, } from '@agentguru/helpers';
export function mountLegProps(leg, index, legs, hasMixedCabins) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (legs === void 0) { legs = []; }
    var isLastLeg = legs.length - 1 === index;
    var currentOrigin = leg.origin;
    var currentDestination = leg.destination;
    var nextOrigin = (_a = legs[index + 1]) === null || _a === void 0 ? void 0 : _a.origin;
    var previousDestination = (_b = legs[index - 1]) === null || _b === void 0 ? void 0 : _b.destination;
    var isOriginChangedAirPort = index === 0 ? false : currentOrigin !== previousDestination;
    var isDestinationChangedAirPort = isLastLeg
        ? false
        : currentDestination !== nextOrigin;
    var connectionTime = isLastLeg
        ? null
        : formatDurationTime(differenceInMinutes(parseDate(String((_c = legs[index + 1]) === null || _c === void 0 ? void 0 : _c.departure_date)), parseDate(String(leg === null || leg === void 0 ? void 0 : leg.arrival_date))));
    var legDetails = [
        parseBaggageLabelWithWeight(leg.fare_info.baggage || ''),
        leg.aircraft,
    ]
        .filter(function (info) { return info; })
        .join(', ');
    return {
        index: index,
        aircraft: leg.aircraft,
        airline: (_d = leg.managed_by_info) === null || _d === void 0 ? void 0 : _d.code,
        airlineName: (_e = leg.managed_by_info) === null || _e === void 0 ? void 0 : _e.long_name,
        managedBy: leg.managed_by,
        flightNumber: leg.flight_number,
        departureDate: leg.departure_date,
        isCodeShare: leg.is_codeshare,
        operatedBy: (_f = leg.operated_by_info) === null || _f === void 0 ? void 0 : _f.long_name,
        origin: leg.origin,
        originName: leg.origin_name,
        duration: leg.duration,
        hasChangedOriginAirPort: isOriginChangedAirPort,
        hasChangedDestinationAirPort: isDestinationChangedAirPort,
        destination: leg.destination,
        destinationName: leg.destination_name,
        arrivalDate: leg.arrival_date,
        isLastLeg: isLastLeg,
        cabin: leg.fare_info.cabin,
        hasMixedCabins: hasMixedCabins,
        fareBasis: leg.fare_info.fare_basis,
        fareClass: leg.fare_info.fare_class,
        legDetails: legDetails,
        hasLongConnections: !!((_g = leg.connection) === null || _g === void 0 ? void 0 : _g.long_connection),
        connectionTime: connectionTime,
    };
}
