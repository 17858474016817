/* eslint-disable class-methods-use-this */
import { ENV } from '@/configurations/env';
import { AnalyticsBrowser } from '@segment/analytics-next';
var Segment = /** @class */ (function () {
    function Segment() {
    }
    Segment.prototype.init = function () {
        var segmentId = ENV.APP_SEGMENT_ID;
        if (!segmentId)
            return null;
        this.instance = AnalyticsBrowser.load({
            writeKey: segmentId,
        });
    };
    Segment.prototype.destroy = function () {
        if (!this.hasInstance())
            return;
        this.instance.reset();
    };
    Segment.prototype.identify = function (user) {
        if (!this.hasInstance())
            return;
        this.instance.identify(user.email);
    };
    Segment.prototype.hasInstance = function () {
        return !!this.instance;
    };
    Segment.prototype.trackNavigation = function (location) {
        if (!this.hasInstance())
            return;
        this.instance.page(location);
    };
    Segment.prototype.track = function (event, properties) {
        if (!this.hasInstance())
            return;
        this.instance.track(event, properties);
    };
    return Segment;
}());
export var segment = new Segment();
