import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useMe, useUserNameInitials } from '@/ui/modules/shared/hooks';
import { AgencyAvatar, Avatar } from '@agentguru/components';
import { useBottomNavBar } from '@agentguru/layout';
import styles from './BottomNavBarUserProfile.module.scss';
export function BottomNavBarUserProfile() {
    var _a, _b, _c, _d;
    var _e = useBottomNavBar(), setShowRoutesModal = _e.setShowRoutesModal, setCurrentRouteByKey = _e.setCurrentRouteByKey;
    var userNameInitials = useUserNameInitials();
    var me = useMe().data;
    var handleNavigateProfile = useCallback(function () {
        setShowRoutesModal(true);
        setCurrentRouteByKey(2);
    }, [setCurrentRouteByKey, setShowRoutesModal]);
    return (_jsxs(_Fragment, { children: [_jsxs("button", { className: styles.userProfileContainer, onClick: handleNavigateProfile, type: "button", children: [_jsxs("div", { className: styles.container, children: [_jsx(Avatar, { size: "md", userNameInitials: userNameInitials, avatarUri: ((_a = me === null || me === void 0 ? void 0 : me.avatar) === null || _a === void 0 ? void 0 : _a.large_uri) || '' }), _jsxs("div", { children: [_jsx("h3", { children: "".concat(me === null || me === void 0 ? void 0 : me.first_name, " ").concat(me === null || me === void 0 ? void 0 : me.last_name) }), _jsx("span", { children: me === null || me === void 0 ? void 0 : me.email })] })] }), _jsx("i", { className: "icon-ag-arrow-right" })] }), _jsx("div", { className: styles.agencyContainer, children: _jsx(AgencyAvatar, { name: ((_b = me === null || me === void 0 ? void 0 : me.agency) === null || _b === void 0 ? void 0 : _b.name) || '', avatarUri: ((_d = (_c = me === null || me === void 0 ? void 0 : me.agency) === null || _c === void 0 ? void 0 : _c.logo) === null || _d === void 0 ? void 0 : _d.small_uri) || '' }) })] }));
}
