import { useListCountriesQuery } from '@/graphql';
export function useCountries() {
    var _a = useListCountriesQuery({}, {
        refetchOnMount: false,
        staleTime: Infinity,
        select: function (data) {
            return (data.listCountries || []).map(function (country) { return ({
                label: String(country.name),
                value: String(country.code),
            }); });
        },
    }), _b = _a.data, countries = _b === void 0 ? [] : _b, isLoading = _a.isLoading;
    return { countries: countries, isLoading: isLoading };
}
