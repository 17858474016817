export function parseSlicesByBooking(booking) {
    var _a;
    return (((_a = booking === null || booking === void 0 ? void 0 : booking.slices) === null || _a === void 0 ? void 0 : _a.map(function (slice) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return ({
            id: slice.id,
            airlineCode: ((_a = slice.airline_info) === null || _a === void 0 ? void 0 : _a.code) || '',
            airlineName: ((_b = slice.airline_info) === null || _b === void 0 ? void 0 : _b.long_name) || '',
            isActive: false,
            isChangeable: ((_c = booking === null || booking === void 0 ? void 0 : booking.short_conditions) === null || _c === void 0 ? void 0 : _c.changeable) === null
                ? null
                : !!((_d = booking === null || booking === void 0 ? void 0 : booking.short_conditions) === null || _d === void 0 ? void 0 : _d.changeable),
            isRefundable: ((_e = booking === null || booking === void 0 ? void 0 : booking.short_conditions) === null || _e === void 0 ? void 0 : _e.refundable) === null
                ? null
                : !!((_f = booking === null || booking === void 0 ? void 0 : booking.short_conditions) === null || _f === void 0 ? void 0 : _f.refundable),
            isDisabled: false,
            fareFamily: ((_h = (_g = slice.legs) === null || _g === void 0 ? void 0 : _g[0].fare_info) === null || _h === void 0 ? void 0 : _h.fare_family) || '',
            legs: ((_j = slice === null || slice === void 0 ? void 0 : slice.legs) === null || _j === void 0 ? void 0 : _j.map(function (leg) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return ({
                    airlineCode: ((_a = leg.operated_by_info) === null || _a === void 0 ? void 0 : _a.code) || '',
                    airlineName: ((_b = leg.operated_by_info) === null || _b === void 0 ? void 0 : _b.long_name) || '',
                    data: {
                        aircraft: leg.aircraft || '',
                        arrival_date: leg.arrival_date || '',
                        departure_date: leg.departure_date || '',
                        origin: leg.origin || '',
                        destination: leg.destination || '',
                        duration: leg.duration || 0,
                        flight_number: leg.flight_number || 0,
                        id: leg.id || '',
                        managed_by: leg.managed_by || '',
                        status: leg.leg_status || 'LEG_STATUS_UNSPECIFIED',
                        fare_info: {
                            baggage: ((_c = leg.fare_info) === null || _c === void 0 ? void 0 : _c.baggage) || 'BAGGAGE_UNSPECIFIED',
                            cabin: ((_d = leg.fare_info) === null || _d === void 0 ? void 0 : _d.cabin) || 'CABIN_UNSPECIFIED',
                            fare_basis: ((_e = leg.fare_info) === null || _e === void 0 ? void 0 : _e.fare_basis) || '',
                            fare_class: ((_f = leg.fare_info) === null || _f === void 0 ? void 0 : _f.fare_class) || '',
                        },
                        is_codeshare: !!leg.is_codeshare,
                        operated_by_info: {
                            code: ((_g = leg.operated_by_info) === null || _g === void 0 ? void 0 : _g.code) || '',
                            long_name: ((_h = leg.operated_by_info) === null || _h === void 0 ? void 0 : _h.long_name) || '',
                        },
                    },
                });
            })) || [],
        });
    })) || []);
}
