import { useState } from 'react';
import { addDaysInDate } from '@agentguru/helpers';
import { t } from '@agentguru/i18n';
export function makeSortByDateOptions() {
    return [
        {
            id: 0,
            description: t('constants.sort_by_bate_today'),
            enabled: true,
            method: function () {
                return [new Date(), new Date()];
            },
        },
        {
            id: 1,
            description: t('constants.sort_by_bate_yesterday'),
            enabled: true,
            method: function () {
                return [addDaysInDate(new Date(), -1), addDaysInDate(new Date(), -1)];
            },
        },
        {
            id: 2,
            description: t('constants.sort_by_bate_7_days'),
            enabled: true,
            method: function () {
                return [new Date(), addDaysInDate(new Date(), -7)];
            },
        },
        {
            id: 3,
            description: t('constants.sort_by_bate_30_days'),
            enabled: true,
            method: function () {
                return [new Date(), addDaysInDate(new Date(), -30)];
            },
        },
        {
            id: 4,
            description: t('constants.sort_by_bate_90_days'),
            enabled: true,
            method: function () {
                return [new Date(), addDaysInDate(new Date(), -90)];
            },
        },
        {
            id: 5,
            description: t('constants.sort_by_bate_specific_date'),
            enabled: true,
            icon: 'ag-arrow-right',
        },
    ];
}
export function useSortByDateValue() {
    var _a = useState(makeSortByDateOptions()[0]), option = _a[0], setOption = _a[1];
    return {
        option: option,
        setOption: setOption,
    };
}
