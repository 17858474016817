var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fetcherWithGraphQLClient } from '@/configurations/reactQuery';
import { CheckCreditDocument, } from '@/graphql';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
export var useCheckCreditLazyQuery = function (options) {
    var _a = useState(), variables = _a[0], setVariables = _a[1];
    var query = useQuery(['CheckCredit', variables], fetcherWithGraphQLClient(CheckCreditDocument, variables), __assign(__assign({}, options), { enabled: !!Object.keys(variables || {}).length }));
    var fetch = useCallback(function (variables) {
        setVariables(variables);
    }, []);
    return [fetch, query];
};
useCheckCreditLazyQuery.getKey = function (variables) { return [
    'CheckCredit',
    variables,
]; };
